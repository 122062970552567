let reviewsSlideList = document.querySelectorAll('.reviews-item');

let maxHeight = 0;

reviewsSlideList.forEach(function (item) {
    item.offsetHeight > maxHeight ? maxHeight = item.offsetHeight : null;
});

reviewsSlideList.forEach(function (item) {
    item.style.height = `${maxHeight}px`;
});

document.addEventListener('DOMContentLoaded', getSlider);


function getSlider() {
    let bannerSlider = $('.banner-slider');
    bannerSlider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        vertical: true,
        verticalSwiping: true,
        dots: true,
        dotsClass: 'vertical-dots',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    autoplay: true,
                    vertical: true,
                    verticalSwiping: false,
                    dots: true,
                    dotsClass: 'vertical-dots',
                }
            }
        ]
    });
    let reviewsSlider = $('.reviews-slider');
    reviewsSlider.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        vertical: true,
        verticalSwiping: true,
        dots: true,
        dotsClass: 'vertical-dots',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                    autoplay: true,
                    vertical: true,
                    verticalSwiping: false,
                    dots: true,
                    dotsClass: 'vertical-dots',
                }
            }
        ]
    });
}

