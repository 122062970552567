// import ymaps from 'ymaps';

ymaps.ready(init);

function init() {
    // Создание карты.
    var myMap = new ymaps.Map("wrapMap", {
        // Координаты центра карты.
        // Порядок по умолчанию: «широта, долгота».
        // Чтобы не определять координаты центра карты вручную,
        // воспользуйтесь инструментом Определение координат.
        center: [54.9980813, 82.8509741],
        // Уровень масштабирования. Допустимые значения:
        // от 0 (весь мир) до 19.
        zoom: 15,
        // controls: ['routePanelControl'],
        controls: ['zoomControl']
    });

    myMap.behaviors.disable('scrollZoom');
    myMap.behaviors.disable('drag');
    myMap.behaviors.disable('multiTouch');

    myMap.geoObjects.add(new ymaps.Placemark([54.9980813, 82.8509741], {}, {
            iconLayout: 'default#image',
            iconImageHref: '../static/img/map-icon.png',
            iconImageSize: [66, 66],
            iconImageOffset: [-3, -42]
        })
    );

    // var control = myMap.controls.get('routePanelControl');
    //
    // // Зададим состояние панели для построения машрутов.
    // control.routePanel.state.set({
    //     // Тип маршрутизации.
    //     type: 'masstransit',
    //     // Выключим возможность задавать пункт отправления в поле ввода.
    //     fromEnabled: false,
    //     // Адрес или координаты пункта отправления.
    //     from: 'Москва, Шереметьевская 85, корпус 1',
    //     // Включим возможность задавать пункт назначения в поле ввода.
    //     toEnabled: true
    //     // Адрес или координаты пункта назначения.
    //     //to: 'Петербург'
    // });
    //
    // // Зададим опции панели для построения машрутов.
    // control.routePanel.options.set({
    //     // Запрещаем показ кнопки, позволяющей менять местами начальную и конечную точки маршрута.
    //     allowSwitch: false,
    //     // Включим определение адреса по координатам клика.
    //     // Адрес будет автоматически подставляться в поле ввода на панели, а также в подпись метки маршрута.
    //     reverseGeocoding: true,
    //     // Зададим виды маршрутизации, которые будут доступны пользователям для выбора.
    //     types: {masstransit: true, pedestrian: true, taxi: true}
    // });

}