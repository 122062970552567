document.addEventListener('DOMContentLoaded', () => {
    let productPictureSlider = $('.product-picture-slider');
    let productPictureNav = $('.product-picture-nav');
    productPictureSlider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        fade: true,
        dotsClass: 'vertical-dots',
        asNavFor: '.product-picture-nav',
    });
    productPictureNav.slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        centerMode: true,
        centerPadding: '0',
        focusOnSelect: true,
        asNavFor: '.product-picture-slider',
    });
});
