const menuBtn = document.querySelector('.menu-btn'),
    menu = document.querySelector('.mob-menu'),
    closedMenuBtn = document.querySelector('.closed-menu-btn');


menuBtn.addEventListener('click', () => {
    menu.classList.add('menu-active');
    document.body.classList.add('body-menu-active');
});

closedMenuBtn.addEventListener('click', () => {
    menu.classList.remove('menu-active');
    document.body.classList.remove('body-menu-active');
});


