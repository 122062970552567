'use strict';

import 'jquery';
import 'popper.js';
import 'slick-carousel';
import '@fancyapps/fancybox';
import 'bootstrap';
import './map';
import './menu';
import './main';
import './product';